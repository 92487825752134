import React, {Component} from "react";
import {getSchemeDetail} from "api/api";
import "./index.css";

const imgClassName = {
    "剧院式": "iconjuyuanshi",
    "宴会式": "iconyanhuishi",
    "课桌式": "iconkezhuoshi",
}

export default class InfoPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detail: {}
        };
    }
    componentDidMount() {
        const {search} = this.props.location;
        if (search) {
            const queryArr = search.slice(1).split("&");
            const queryFormat = {};
            queryArr.forEach(item => {
                const arr = item.split("=");
                queryFormat[arr[0]] = arr[1];
            });
            document.title = queryFormat.title ? decodeURIComponent(queryFormat.title) : "国家会议中心";
            if (queryFormat.id) {
                getSchemeDetail({planId: queryFormat.id}).then(res => {
                    this.setState({
                        detail: res
                    });
                    let radios = document.getElementsByClassName('radioDom');
                    if (radios) {
                        for (let i = 0; i < radios.length; i++ ) {
                            radios[i].disablePictureInPicture = true;
                        } 
                    }
                })
            }
        }
    }
    handleBackToWX = () => {
        if (!window.isInWeb) {
            window.wx.miniProgram.navigateBack();
        }
    }
    render() {
        const {detail} = this.state;
        const {baseInfo = [], planInfo = [], effectPicUrls = [], videoUrls = [], roomInfo = {}} = detail;
        return (
            <div className="info-page">
                <div className="info-title">综合信息</div>
                <div className="info-content">
                    <div className="info-block">
                        <div className="block-title">基本信息</div>
                        <div className="block-detail">
                            {
                                baseInfo.map((item, ind) => {
                                    return (
                                        <div key={ind}>
                                            <span>{item.k}：</span>
                                            <span className="block-detail-text">{item.v}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="info-block">
                        <div className="block-title">方案信息</div>
                        <div className="block-detail">
                            {
                                planInfo.map((item, ind) => {
                                    return (
                                        <div key={ind}>
                                            <span>{item.k}：</span>
                                            <span className="block-detail-text">{item.v}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div>
                            {
                                effectPicUrls.map((item, ind) => {
                                    return (
                                        <div key={ind} className="detial-img">
                                            <img src={item.v} alt="" />
                                        </div>
                                    )
                                })
                            }
                            {
                                videoUrls.map((item, ind) => {
                                    return (
                                        <div key={ind} className="detial-img">
                                            <video src={item.v} controls="controls" controlsList="nodownload" className="radioDom" />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="info-block">
                        <div className="block-title" style={{paddingTop: "0.2rem"}}>空间信息</div>
                        <div className="block-detail">
                            <div className="space-info-title">基本信息</div>
                            {
                                roomInfo.baseInfo && roomInfo.baseInfo.map((item, ind) => {
                                    return (
                                        <div key={ind}>
                                            <span>{item.k}：</span>
                                            <span className="block-detail-text">{item.v}</span>
                                        </div>
                                    )
                                })
                            }
                            {
                                roomInfo.maxCapacity && roomInfo.maxCapacity.length ? <React.Fragment>
                                    <div className="space-info-title">最大容量</div>
                                    <div className="space-capacity-list">
                                        {
                                            roomInfo.maxCapacity.map((item, ind) => {
                                                return (
                                                    <div className="capacity-item" key={ind}>
                                                        <div className="capacity-icon-box">
                                                            <span className={`iconfont ${imgClassName[item.k]}`}></span>
                                                        </div>
                                                        <div className="capacity-item-text">{item.k}</div>
                                                        <div>{item.v}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </React.Fragment> : null}
                        </div>
                        {roomInfo.floorPosThumb ? <div className="detial-img">
                            <img src={roomInfo.floorPosThumb} alt=""/>
                        </div> : null}
                    </div>
                </div>
                {!window.isInWeb ? <div className="back-btn" onClick={this.handleBackToWX}>
                    <span className="iconfont iconguanbi"></span>
                </div> : null}
            </div>
        )
    }
}
import qs from "qs";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const request = (url, config) => {
    return fetch(BASE_URL + url, config).then(res => {
        if (!res.ok) {
            throw Error("");
        }
        return res.json();
    }).then(resJson => {
        if (resJson.code === 0) {
            return resJson.data;
        }
        return resJson;
    }).catch(err => {
        console.log(err);
    });
}

const get = ({url, data}) => {
  const resUrl = url + "?" + qs.stringify(data);
  return request(resUrl, { method: 'GET' })
}

export {
    get
}
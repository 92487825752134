import {get} from "./request";
export const getRoomProgramList = (query) => get({
  url: "miniprogram/panorama",
  data: query
});
export const getRoomDetail = (query) => get({
  url: "miniprogram/room/detail",
  data: query
});
export const getSchemeDetail = (query) => get({
  url: "miniprogram/output/integratedInfo",
  data: query
});
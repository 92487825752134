import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import RoutePage from './router';
import "./assets/iconfont/iconfont.css"

ReactDOM.render(
  <React.StrictMode>
    <RoutePage/>
  </React.StrictMode>,
  document.getElementById('root')
);


import {HashRouter, Route, Switch} from "react-router-dom";
import PanoramaPage from "views/Panorama/Index";
import InfoPage from "views/Info/Index";
function RoutePage() {
  return (
    <HashRouter>
      <Switch>
        <Route path="/panorama" exact component={PanoramaPage} />
        <Route path="/info" exact component={InfoPage} />
        <Route path="*" render={() => <div>page not found</div>} />
      </Switch>
    </HashRouter>
  );
}

export default RoutePage;
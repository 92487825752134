import React, {Component} from "react";
import {getRoomProgramList} from "api/api";
import DetailModal from "./compoents/DetailModal";
import "./index.css";
import "src/libs/pannellum.css";
export default class PanoramaPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listBoxVisible: false,
            currentImg: 0,
            imgList: [],
            modalVisible: false,
            modalDetail: {},
            isShowSchemeDetail: false
        };
        this.clientWidth = document.body.clientWidth;
        this.panoramaRef = React.createRef();
        this.pannellum = "";
        this.viewer = "";
        this.isLoading = true;
        this.searchQuery = {};
    }
    componentDidMount() {
        const {search} = this.props.location;
        if (search) {
            const queryArr = search.slice(1).split("&");
            const queryFormat = {};
            queryArr.forEach(item => {
                const arr = item.split("=");
                queryFormat[arr[0]] = arr[1];
            });
            this.searchQuery = queryFormat;
            document.title = queryFormat.title ? decodeURIComponent(queryFormat.title) : "国家会议中心";
            getRoomProgramList({id: this.searchQuery.panoramaId}).then(res => {
                if (res) {
                    const config = JSON.parse(res.config);
                    const viewpointThumb = res.viewpointThumb;
                    const imgList = [];
                    Object.keys(config.scenes).forEach((k, ind) => {
                        const item = config.scenes[k];
                        item.hotSpots.forEach(spot => {
                            spot.cssClass = "custom-hotspot";
                            spot.createTooltipFunc = this.customHotSpot;
                            spot.createTooltipArgs = spot.sceneId;
                        });
                        imgList.push({
                            imgUrl: viewpointThumb[`${item.id}.png`],
                            viewPoint: k
                        });
                    });
                    this.setState({
                        imgList,
                        isShowSchemeDetail: !!res.infoPostStatus
                    });
                    this.initPannellum(config);
                }
            })
        }
    }
    initPannellum = (cfg) => {
        const dom = this.panoramaRef && this.panoramaRef.current;
        if (dom) {
            this.viewer = window.pannellum.viewer(dom, Object.assign({
                autoLoad: true,
                showControls: false
            }, cfg));
            this.viewer.on("scenechange", (name) => {
                this.isLoading = true;

                const {imgList} = this.state;
                const ind = imgList.findIndex(item => item.viewPoint === name);
                this.handleChangeViewr(name, ind);
            });
            this.viewer.on("load", () => {
                this.isLoading = false;
            });
        }
    }
    handleChangeViewr = (name, ind) => {
        this.setState({
            currentImg: ind,
            listBoxVisible: false
        });
        this.viewer.loadScene(name);
    }

    customHotSpot = (hotSpotDiv, args) => {
        hotSpotDiv.classList.add('custom-tooltip');
        var span = document.createElement('span');
        span.innerHTML = args;
        hotSpotDiv.appendChild(span);
        if (span.scrollWidth > this.clientWidth / 4) {
            span.style.width = this.clientWidth / 4 + 'px';
        } else {
            span.style.width = span.scrollWidth + 'px';
        }
        span.style.whiteSpace = "initial";
        span.style.marginTop = -(span.scrollHeight - hotSpotDiv.offsetHeight) / 2 + 'px';

    }
    getDetail = () => {
        this.props.history.push(`info?title=${this.searchQuery.title}&id=${this.searchQuery.schemeId}`)
    }
    closeModal = () => {
        this.setState({
            modalVisible: false
        });
    }

    handleBackToWX = () => {
        if (!window.isInWeb) {
            window.wx.miniProgram.navigateBack();
        }
    }
    render() {
        const {imgList, currentImg, listBoxVisible, modalVisible, modalDetail, isShowSchemeDetail} = this.state;
        return (
            <div className="panorama-page">
                <div
                    id="viewer"
                    className="panorama-box"
                    ref={this.panoramaRef}
                    onClick={() => listBoxVisible && this.setState({listBoxVisible: false})}
                ></div>
                <div
                    className="img-list-box"
                    style={{right: listBoxVisible ? "0" : "-1.95rem"}}
                >
                    {imgList.map((item, ind) => {
                        return (
                            <div key={ind}
                                className="img"
                                style={{borderColor: currentImg === ind ? "#fff" : "transparent"}}
                                onClick={() => this.handleChangeViewr(item.viewPoint, ind)}
                            >
                                <img src={item.imgUrl} style={{width: "100%", height: "100%"}} alt={item.viewPoint} />
                            </div>
                        )
                    })}
                </div>
                <div className="btn-list">
                    <div className="btn" onClick={() => !this.isLoading && this.setState({listBoxVisible: true})}>
                        <span className="iconfont iconshidianliebiao"></span>
                    </div>
                    {isShowSchemeDetail && <div className="btn" onClick={() => this.getDetail()}>
                        <span className="iconfont iconkongjianxinxi"></span>
                    </div>}
                    {!window.isInWeb && <div className="btn" onClick={this.handleBackToWX}>
                        <span className="iconfont iconguanbi"></span>
                    </div>}
                </div>
                <DetailModal
                    open={modalVisible}
                    detail={modalDetail}
                    handleClose={this.closeModal}
                />
            </div>
        )
    }
}
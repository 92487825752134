import React, {Component} from "react";
import "./detailModal.css"

const imgClassName = {
    "剧院式": "iconjuyuanshi",
    "宴会式": "iconyanhuishi",
    "课桌式": "iconkezhuoshi",
}

export default class DetailModal extends Component {
    render() {
        const {open = false, detail = {}, handleClose} = this.props;
        return (
            <div className="detail-box" style={{display: open ? "block" : "none"}}>
                <div className="detail-content">
                    <div className="detail-modal-title">空间信息</div>
                    <div className="detail-baseinfo-box">
                        <div className="detail-sec-title">基本信息</div>
                        <div className="detail-baseinfo-list">
                            {detail.baseInfo && detail.baseInfo.map((item, ind) => {
                                return (
                                    <div key={ind}>
                                        <span>{item.k}：</span>
                                        <span className="detail-baseinfo-text">{item.v}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {detail.maxCapacity && detail.maxCapacity.length && <div>
                        <div className="detail-sec-title">最大容量</div>
                        <div className="detail-capacity-list">
                            {detail.maxCapacity && detail.maxCapacity.map((item, ind) => {
                                return (
                                    <div className="detail-capacity-item" key={ind}>
                                        <div className="detail-capacity-icon-box">
                                            <span className={`iconfont ${imgClassName[item.k]}`}></span>
                                        </div>
                                        <div className="detail-capacity-item-text">{item.k}</div>
                                        <div>{item.v}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>}
                </div>
                <div className="close-btn" onClick={handleClose}>
                    <span className="iconfont iconguanbi"></span>
                </div>
            </div>
        )
    }
}